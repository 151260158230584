import { NavLink, useNavigate } from "react-router-dom";
import SignInimg from "../../assets/images/Sign-in.svg";
import { useState } from "react";
import axios from 'axios'
import { toast } from 'react-hot-toast'

const SignIn = () => {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  const handleChange = (name, value) => {
    setUserData({ ...userData, [name]: value });
  };

  const SignIn = async () => {
    try {
      const response = await axios.post("/auth/login", userData);
      const { data, token } = response.data.payload
      localStorage.setItem("jwtToken", token)
      localStorage.setItem("userData", JSON.stringify(data))
      window.location.href = "/"
      toast.success(response.data.message);
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <div className="signin-container">
        <div className="signin-forms-container">
          <div className="signin-signup">
            <form action="#" className="sign-in-form">
              <h2 className="forms-title">Sign in</h2>
              <div className="forms-input-field">
                <i className="fas fa-user"></i>
                <input type="forms-text" placeholder="Username" value={userData?.email || ""} onChange={(e) => handleChange("email", e.target.value)} required />
              </div>
              <div className="forms-input-field">
                <i className="fas fa-lock"></i>
                <input type="password" placeholder="Password" value={userData?.password || ""} onChange={(e) => handleChange("password", e.target.value)} />
              </div>
              <input
                type="button"
                onClick={() => SignIn()}
                value="Sign In"
                className="forms-btn solid"
                required
              />
              <NavLink to="/forgotPassword" className="forgotPassword">
                Forgot Password?
              </NavLink>
            </form>
          </div>
        </div>
        <div className="signup-panels-container">
          <div className="signup-panel signup-left-panel">
            <div className="signup-content">
              <h3>New to our community ?</h3>
              <p>
                Discover a world of possibilities! Join us and explore a vibrant
                community where ideas flourish and connections thrive.
              </p>
              <NavLink to="/signup">
                <button className="forms-btn transparent" id="sign-up-btn">
                  Sign up
                </button>
              </NavLink>
            </div>
            <img src={SignInimg} className="signup-image" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
export default SignIn;
