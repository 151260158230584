import { NavLink } from "react-router-dom";
import Service2 from "../assets/images/services-2.png";
import Service3 from "../assets/images/services-3.png";
import Service4 from "../assets/images/services-4.png";
import Car1 from "../assets/images/cars/car-2.jpg";
import Feature1 from "../assets/images/feature-1.svg";
import Feature2 from "../assets/images/feature-2.svg";
import Feature3 from "../assets/images/feature-3.svg";
import Feature4 from "../assets/images/feature-4.svg";
import Feature5 from "../assets/images/feature-5.svg";
import Feature6 from "../assets/images/feature-6.svg";
import OwlCarousel from "react-owl-carousel";
import { CAR_HOME_CARO } from "../config/setting";
import { useEffect, useState } from "react";
import axios from "axios";

const Home = () => {
  const [carList, setCarList] = useState([]);

  const getCarList = async () => {
    try {
      const response = await axios.get("/user/cars?pagination=false");
      setCarList(response.data.payload);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCarList();
  }, []);

  return (
    <>
      <section className="heroImage">
        <div className="container-fluid">
          <div className="row">
            <div className="container-flex col-lg-6 col-md-12 col-sm-12 col-xl-6 col-12">
              <div>
                <h1 className="main-title">
                  We aim to provide car services in every nook and cranny of the
                  United Kingdom.
                </h1>

                <h1 className="main-title">Book Your Appointment Today.</h1>
                <p className="sub-title">
                  Everyone deserves a good drive and we carry a vision to have
                  major stake-holding in providing the same across the globe in
                  decades to come.
                </p>
                <div className="d-flex mt-5">
                  <NavLink to="/appointment">
                    <button className="btn-main">Book Appointment</button>
                  </NavLink>
                  <NavLink to="/signup">
                    <button className="btn-main ml-4">Signup</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-title">
                <span className="section-span">Our Services</span>
                <h2>What We Offers?</h2>
                <p>
                  "Auto ARC: Your Destination for Quality Cars and Exceptional
                  Service."
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="service-card text-center">
                <img className="service-image" src={Service3} alt="" />
                <div className="service-card-body">
                  <h5 className="service-card-title pt-3">Car Repair</h5>
                  <p className="service-card-text">
                    Our expert technicians provide top-quality car repair
                    services to keep your vehicle running smoothly. Using
                    state-of-the-art equipment and genuine parts, we ensure
                    precise diagnostics and reliable repairs for all makes and
                    models. Trust us for prompt, efficient, and professional
                    automotive care that gets you back on the road safely.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="service-card text-center">
                <img className="service-image" src={Service2} alt="" />
                <div className="service-card-body">
                  <h5 className="service-card-title pt-3">Car Selling</h5>
                  <p className="service-card-text">
                    We offer a wide selection of high-quality vehicles to suit
                    every lifestyle and budget. Our friendly sales team is
                    dedicated to helping you find the perfect car with
                    transparent, no-pressure service. Experience a seamless and
                    enjoyable car buying process with us, where customer
                    satisfaction is our top priority.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="service-card text-center">
                <img className="service-image" src={Service3} alt="" />
                <div className="service-card-body">
                  <h5 className="card-title pt-3">Car Maintenance</h5>
                  <p className="service-card-text">
                    Our car maintenance services ensure your vehicle remains in
                    peak condition. Our skilled technicians perform routine
                    checks and services using the latest tools and genuine
                    parts. Trust us to provide reliable, efficient maintenance
                    that keeps your car running smoothly and safely.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="service-card text-center">
                <img className="service-image" src={Service4} alt="" />
                <h5 className="service-card-title pt-3">Customer Support</h5>
                <p className="service-card-text">
                  Exceptional customer support is our hallmark. Our dedicated
                  team is always ready to assist with any questions or concerns,
                  ensuring a seamless and satisfying experience. Trust us to
                  provide prompt, friendly, and professional service every step
                  of the way.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="carSell">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="carSell-title">
                <span className="section-span">Cars for Sale</span>
                <h2>Buy Your Own Car Here</h2>
                <p>
                  "Drive into Excellence: Find Your Perfect Car at Auto ARC."
                </p>
              </div>
            </div>
          </div>
          <OwlCarousel
            className="owl-theme carSell-item-pic-slider"
            {...CAR_HOME_CARO}
          >
            {carList && carList.length > 0 ? (
              carList.map((c, index) => (
                <div className="carSell-item" key={index}>
                  <img src={c?.pictures?.[0] || Car1} alt="" />
                  <div className="carSell-item-text">
                    <div className="carSell-item-text-inner">
                      <h5>
                        <NavLink to="#">{c.car_name}</NavLink>
                      </h5>
                      <div className="car-date">Year: {c.car_year}</div>
                      <ul>
                        <li>
                          <span className="car-sale-span">{c.car_miles}</span> mi
                        </li>
                        <li>{c.gear_type}</li>
                        <li>
                          <span className="car-sale-span">{c.engine}</span> hp
                        </li>
                      </ul>
                    </div>
                    <div className="carSell-item-price">
                      <span className="car-option sell">For Selling</span>
                      <h6>${c.price}</h6>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h1 className="text-center">No Car Found</h1>
            )}
          </OwlCarousel>
        </div>
      </section>

      <div className="serviceFeature">
        <div className="container">
          <div className="row">
            <div className="serviceFeature-title mb-5">
              <h4 className="section-span">Our Features</h4>
              <h2>Why Choose Us?</h2>
              <h6>
                "Restore Confidence in Your Drive: Premier Car Repair at Auto
                ARC."
              </h6>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div className="text-center">
                <img className="serviceFeature-image" src={Feature1} alt="" />
                <div className="serviceFeature-card-body">
                  <h5 className="serviceFeature-card-title pt-3">
                    Oil Changes
                  </h5>
                  <p className="serviceFeature-card-text">
                    Regular oil changes are essential for maintaining engine
                    health and performance, typically recommended every 5,000 to
                    7,500 miles or as per manufacturer guidelines.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div className="text-center">
                <img className="serviceFeature-image" src={Feature2} alt="" />
                <div className="serviceFeature-card-body">
                  <h5 className="serviceFeature-card-title pt-3">
                    Air Conditioning
                  </h5>
                  <p className="serviceFeature-card-text">
                    Regular maintenance of your car's air conditioning system
                    ensures optimal cooling performance and prevents potential
                    malfunctions, such as leaks or compressor failures.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div className="text-center">
                <img className="serviceFeature-image" src={Feature3} alt="" />
                <div className="serviceFeature-card-body">
                  <h5 className="serviceFeature-card-title pt-3">
                    Auto Electric
                  </h5>
                  <p className="serviceFeature-card-text">
                    Your car battery plays a vital role in starting the engine
                    and powering electrical components, typically lasting 3 to 5
                    years depending on usage and climate.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div className="text-center">
                <img className="serviceFeature-image" src={Feature4} alt="" />
                <div className="serviceFeature-card-body">
                  <h5 className="serviceFeature-card-title pt-3">
                    Brake Service
                  </h5>
                  <p className="serviceFeature-card-text">
                    Regular brake service is crucial for ensuring optimal
                    stopping power and safety on the road, with brake pads
                    typically needing replacement every 30,000 to 70,000 miles
                    depending on driving habits and vehicle type.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div className="text-center">
                <img className="serviceFeature-image" src={Feature5} alt="" />
                <div className="serviceFeature-card-body">
                  <h5 className="serviceFeature-card-title pt-3">
                    Transmission
                  </h5>
                  <p className="serviceFeature-card-text">
                    Your car's transmission is responsible for shifting gears
                    smoothly, with regular maintenance such as fluid changes
                    recommended to prolong its lifespan and prevent costly
                    repairs.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div className="text-center">
                <img className="serviceFeature-image" src={Feature6} alt="" />
                <div className="serviceFeature-card-body">
                  <h5 className="serviceFeature-card-title pt-3">
                    Tire & Wheel service
                  </h5>
                  <p className="serviceFeature-card-text">
                    Regular tire rotations and wheel alignments help ensure even
                    tread wear and optimal handling, extending the lifespan of
                    your tires and enhancing overall driving performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
