import SignInimg from "../../assets/images/Sign-in.svg";
import { NavLink } from "react-router-dom";

const SignIn = () => {
    return (
        <>
            <div className="signin-container">
                <div className="signin-forms-container">
                    <div className="signin-signup">
                        <form action="#" className="sign-in-form">
                            <h2 className="forms-title">Change Password</h2>
                            <div className="forms-input-field">
                                <i className="fas fa-lock"></i>
                                <input type="password" placeholder="New Password" required />
                            </div>
                            <div className="forms-input-field">
                                <i className="fas fa-lock"></i>
                                <input type="password" placeholder="Confirm Password" />
                            </div>
                            <input type="submit" value="Submit" className="forms-btn solid" required />
                        </form>
                    </div>
                </div>
                <div className="signup-panels-container">
                    <div className="signup-panel signup-left-panel">
                        <div className="signup-content">
                            <h3>Have you change you password?</h3>
                            <p>
                                Don't worry.
                                Your data is our top most priority. We are protecting your information.
                            </p>
                            <NavLink to="/signin">
                                <button className="forms-btn transparent" id="sign-up-btn">
                                    Sign In
                                </button>
                            </NavLink>
                        </div>
                        <img src={SignInimg} className="signup-image" alt="" />
                    </div>
                </div>
            </div>
        </>
    );
};
export default SignIn;