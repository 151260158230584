import Footer from "../components/footer";
import Header from "../components/header";
import Routers from "../routers/route";

const Layout = () => {
  return (
    <>
      <Header />
      <Routers />
      <Footer />
    </>
  );
}

export default Layout;