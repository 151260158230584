
export const CAR_HOME_CARO = {
    loop: true,
    responsiveClass: true,
    dots: true,
    autoplay :true,
    nav : false,
    autoplayHoverPause: true,
    autoplaySpeed: 3000,
    margin: 30,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  export const CLIENT_CARO = {
    loop: false,
    responsiveClass: true,
    dots: true,
    autoplay :true,
    nav : false,
    autoplayHoverPause: true,
    autoplaySpeed: 3000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };
  