import { useState } from "react";
import SignInimg from "../../assets/images/Sign-in.svg";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-hot-toast'

const Appointment = () => {

  const [appointmentData, setAppointmentData] = useState({})
  const navigate = useNavigate()

  const handleChange = (name, value) => {
    setAppointmentData({ ...appointmentData, [name]: value })
  }

  const BookAppointment = async () => {
    try {
      const response = await axios.post("/user/book-appointment", appointmentData)
      console.log(response);
      navigate("/")
      toast.success(response.data.message)
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message)
    }
  }

  return (
    <>
      <div className="appointment-container">
        <div className="appointment-forms-container">
          <div className="signin-signup">
            <form action="#" className="sign-in-form">
              <h2 className="forms-title">Appointment Form</h2>
              <div className="forms-input-field">
                <i className="fas fa-user"></i>
                <input type="forms-text" placeholder="Fisrt Name" value={appointmentData?.first_name || ""} onChange={(e) => handleChange("first_name", e.target.value)} required />
              </div>
              <div className="forms-input-field">
                <i className="fas fa-user"></i>
                <input type="forms-text" placeholder="Last Name" value={appointmentData?.last_name || ""} onChange={(e) => handleChange("last_name", e.target.value)} required />
              </div>
              <div className="forms-input-field">
                <i className="fas fa-envelope"></i>
                <input type="email" placeholder="Email" value={appointmentData?.email || ""} onChange={(e) => handleChange("email", e.target.value)} required />
              </div>
              <div className="forms-input-field">
                <i className="fas fa-phone"></i>
                <input type="tel" placeholder="Phone Number" value={appointmentData?.phone || ""} onChange={(e) => handleChange("phone", e.target.value)} required />
              </div>
              <div className="forms-input-field">
                <i className="fas fa-calendar-alt"></i>
                <input type="date" placeholder="Select Date" value={appointmentData?.appointment_date || ""} onChange={(e) => handleChange("appointment_date", e.target.value)} required />
              </div>
              <div className="forms-input-field">
                <i className="fas fa-clock"></i>
                <input type="time" placeholder="Select Time" value={appointmentData?.appointment_time || ""} onChange={(e) => handleChange("appointment_time", e.target.value)} required />
              </div>
              <div className="forms-input-field">
                <i className="fas fa-envelope"></i>
                <input type="text-area" value={appointmentData?.remark || ""} onChange={(e) => handleChange("remark", e.target.value)} placeholder="Type Your Message Here" />
              </div>
              <input type="button" value="Submit" onClick={() => BookAppointment()} className="forms-btn solid" />
            </form>
          </div>
        </div>
        <div className="signup-panels-container">
          <div className="signup-panel signup-left-panel">
            <div className="signup-content">
              <h3>Have You Book Appointment?</h3>
              <p>
                Our team will contact you shortly about your requirement. Stay
                Connected.
              </p>
            </div>
            <img src={SignInimg} className="signup-image" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Appointment;
