import { Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import About from "../pages/About/about";
import CarRepair from "../pages/carRepair"
import Contact from "../pages/contact"
import BuyCar from "../pages/BuyCar/BuyCar";
import CarDetails from "../pages/BuyCar/CarDetails";
import SignIn from "../pages/SignIn/SignIn";
import SignUp from "../pages/SignUp/SignUp";
import Appointment from "../pages/Appointment/Appointment"
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword"


const Routers = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/carRepair" element={<CarRepair />} />
                <Route path="/buycar" element={<BuyCar />} />
                <Route path="/car-details/:id" element={<CarDetails />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/appointment" element={<Appointment />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
            </Routes>
        </>
    );
}

export default Routers;