import { BrowserRouter } from "react-router-dom";
import Layout from "./layout/layout";
import './assets/css/style.css';

const App = () => {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
}

export default App;
