import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                <div>
                  <p className="footer-logo-text">Auto ARC</p>
                  <p className="footer-text">
                    Auto ARC is your trusted destination for all your automotive needs. With a commitment to excellence and customer satisfaction, we offer top-quality vehicles, expert car repairs, and comprehensive maintenance services. Experience the Auto ARC difference today.
                  </p>
                  <div className="d-flex mt-4 mb-5 mb-lg-0">
                    <a href="https://www.facebook.com/profile.php?id=61556522022543" target="_blank"><i className="fab fa-facebook-f social"></i></a>
                    <a href="https://www.instagram.com/autoarcuk?igsh=cHE0anphc3ZyNmYy&utm_source=qr" target="_blank"><i className="fab fa-instagram social ml-4"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                <p className="footer-title">Pages</p>
                <NavLink to="/buycar">
                  <p className="footer-text"><i className="fa fa-angle-right"></i> Buy Car</p>
                </NavLink>
                <NavLink to="/carRepair">
                  <p className="footer-text"><i className="fa fa-angle-right"></i> Repair Car</p>
                </NavLink>
                <NavLink to="/about">
                  <p className="footer-text"><i className="fa fa-angle-right"></i> About Us</p>
                </NavLink>
                <NavLink to="/contact">
                  <p className="footer-text"><i className="fa fa-angle-right"></i> Contact Us</p>
                </NavLink>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                <p className="footer-title">Services</p>
                <NavLink to="/buycar">
                  <p className="footer-text"><i className="fa fa-angle-right"></i> Car Selling</p>
                </NavLink>
                <NavLink to="/carRepair">
                  <p className="footer-text"><i className="fa fa-angle-right"></i> Car Repair</p>
                </NavLink>
                <NavLink to="/contact">
                  <p className="footer-text"><i className="fa fa-angle-right"></i> Customer Support</p>
                </NavLink>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <p className="footer-title">Contact</p>
                <p className="footer-text">
                  <i className="fas fa-phone-alt mr-2"></i>+44 7534950538
                </p>
                <p className="footer-text">
                  <i className="far fa-envelope mr-2"></i>autoarc.uk@gmail.com
                </p>
                <div className="d-flex footer-text">
                  <i className="fas fa-map-marker-alt mr-2 mt-2"></i>
                  <p>Harrow, Middlesex, United Kingdom</p>
                </div>
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 className="footer-copyright">
                  Copyright © Auto ARC 2024. All Rights Reserved.
                </h6>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </>
  );
};

export default Footer;
