import { NavLink, useNavigate } from "react-router-dom";
import SignUpimg from "../../assets/images/Sign-up.svg";
import axios from 'axios'
import { useState } from "react";
import toast from 'react-hot-toast';
// import SignInimg from "../../assets/images/Sign-in.svg";

const SignUp = () => {

  const [userData, setUserData] = useState({})
  const navigate = useNavigate()

  const handleChange = (name, value) => {
    setUserData({...userData, [name]: value})
  }

  const SignUp = async() => {
    try{
      const response = await axios.post("/auth/sign-up", userData)
      console.log(response);
      navigate("/signin")
      toast.success(response.data.message)
    }catch(error){
      console.log("error", error);
      toast.error(error.response.data.message)
    }
  }

  return (
    <>
      <div className="signup-container">
        <div className="signup-forms-container">
          <div className="signin-signup">
            <form action="#" className="sign-in-form">
              <h2 className="forms-title">Sign up</h2>
              <div className="forms-input-field">
                <i className="fas fa-user"></i>
                <input type="text" placeholder="First Name" value={userData?.first_name || ""} onChange={(e) => handleChange("first_name", e.target.value)} required />
              </div>
              <div className="forms-input-field">
                <i className="fas fa-user"></i>
                <input type="text" placeholder="Last Name" value={userData?.last_name || ""} onChange={(e) => handleChange("last_name", e.target.value)} required />
              </div>
              <div className="forms-input-field">
                <i className="fas fa-envelope"></i>
                <input type="email" placeholder="Email" value={userData?.email || ""} onChange={(e) => handleChange("email", e.target.value)} required />
              </div>
              <div className="forms-input-field">
                <i className="fas fa-lock"></i>
                <input type="password" placeholder="Password" value={userData?.password || ""} onChange={(e) => handleChange("password", e.target.value)} required />
              </div>
              <div className="forms-input-field">
                <i className="fas fa-lock"></i>
                <input type="password" placeholder="Confirm Password" value={userData?.confirm_password || ""} onChange={(e) => handleChange("confirm_password", e.target.value)} required />
              </div>
              <div className="sign-up-checkbox">
                <input id="checkbox" type="checkbox" checked={userData?.terms || false} onChange={(e) => handleChange("terms", e.target.checked)}  required />
                <label for="checkbox">
                  {" "}
                  I agree to these{" "}
                  <NavLink to="#">Terms and Conditions</NavLink>.</label>
              </div>
              <input type="button" onClick={() => SignUp()} className="forms-btn" value="Sign up" />
            </form>
          </div>
        </div>

        <div className="signup-panels-container">
          <div className="signup-panel signup-left-panel">
            <div className="signup-content">
              <h3>Already Have an Account?</h3>
              <p>
                You can Sign In by clicking the below button.
              </p>
              <NavLink to="/signin">
                <button className="forms-btn transparent" id="sign-up-btn">
                  Sign In
                </button>
              </NavLink>
            </div>
            <img src={SignUpimg} className="signup-image" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
export default SignUp;
