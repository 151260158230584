import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logoAA.svg";


const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : "";

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    const navLinks = document.querySelectorAll('.nav-link');
    const menuToggle = document.getElementById('navbarSupportedContent');

    const handleNavLinkClick = () => {
      const menuCollapse = new window.bootstrap.Collapse(menuToggle, { toggle: false });
      menuCollapse.hide();
    };

    navLinks.forEach(link => link.addEventListener('click', handleNavLinkClick));

    return () => {
      navLinks.forEach(link => link.removeEventListener('click', handleNavLinkClick));
    }
  }, []);
  return (
    <>
      <header
        className={
          !scrolled ? "header top-header" : "header-sticky top-header shadow"
        }
      >
        <nav className="navbar navbar-expand-lg main-nav">
          <div className="container">
            <NavLink to="/" className="navbar-brand d-flex align-items-center">
              <img
                className="img-fluid logo"
                width="22%"
                src={Logo}
                alt="logo"
              />
              <h1 className="ml-2 mb-0 logo-txt">Auto ARC</h1>
            </NavLink>

            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i class="fa fa-bars"></i>
            </button>

            <div
              className="collapse navbar-collapse justify-content-end fill"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink className="nav-link" activeClassName="active" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/buycar"
                  >
                    Buy Car
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/carRepair"
                  >
                    Repair Car
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/about"
                  >
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/contact"
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
              {!userData?.first_name ? (
                <NavLink to="/signup">
                  <button className="btn-main">Sign up</button>
                </NavLink>
              ) : (
                <NavLink className="nav-link" activeClassName="active" to="/">
                  <span>
                    <b>{userData?.first_name}</b>
                  </span>
                </NavLink>
              )}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
