import Contact from "../assets/images/contact.svg";

const contact = () => {
    return (
        <>
            <div className="signup-container">
                <div className="signup-forms-container">
                    <div className="signin-signup">
                        <form action="#" className="sign-in-form">
                            <h2 className="forms-title">Contact Us</h2>
                            <div className="forms-input-field">
                                <i className="fas fa-user"></i>
                                <input type="forms-text" placeholder="Fisrt Name" required />
                            </div>
                            <div className="forms-input-field">
                                <i className="fas fa-user"></i>
                                <input type="forms-text" placeholder="Last Name" required />
                            </div>
                            <div className="forms-input-field">
                                <i className="fas fa-envelope"></i>
                                <input type="email" placeholder="Email" required />
                            </div>
                            <div className="forms-input-field">
                                <i className="fas fa-phone"></i>
                                <input type="tel" placeholder="Phone Number" required />
                            </div>
                            <div className="forms-input-field">
                                <i className="fas fa-envelope"></i>
                                <input type="text-area" placeholder="Type Your Message Here" required />
                            </div>
                            <input type="submit" value="Submit" className="forms-btn solid" />
                        </form>
                    </div>
                </div>
                <div className="signup-panels-container">
                    <div className="signup-panel signup-left-panel">
                        <div className="signup-content">
                            <h3>How Can We Help?</h3>
                            <p>
                                Our team will contact you shortly about your requirement.
                                Stay Connected.
                            </p>
                        </div>
                        <img src={Contact} className="signup-image" alt="" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default contact;