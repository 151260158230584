import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import car3 from "../../assets/images/car3.jpeg";
import car1 from "../../assets/images/car1.jpeg";
import car2 from "../../assets/images/car2.jpeg";
import car5 from "../../assets/images/car5.jpeg";
import car4 from "../../assets/images/car4.jpeg";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import axios from "axios";

const CarDetails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [carDetail, setCarDetail] = useState({});
  const [carGallary, setCarGallary] = useState([]);
  const { id } = useParams();

  const getCarDetail = async (car_id) => {
    try {
      const response = await axios.get(`/user/cars/${car_id}`);
      setCarDetail(response.data.payload);
      if (response.data.payload?.pictures.length > 0) {
        let arr = [];
        response.data.payload.pictures.map((i) => arr.push({ image: i }));
        setCarGallary(arr);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (id) {
      getCarDetail(id);
    }
  }, [id]);
  return (
    <>
      <div className="container pt-3">
        <NavLink to="/buycar">
          <button type="button" class="btn btn-secondary">
            <i className="fa fa-arrow-left"></i>&nbsp; Back
          </button>
        </NavLink>
      </div>
      <div className="car-detail-page">
        <div className="container">
          <div className="main-content">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 left-box">
                <div className="car-box">
                  <img
                    src={carDetail?.pictures?.[0] || car3}
                    className="img-fluid"
                    alt="car"
                  />

                  <div className="view-car-b">
                    <button
                      className="btn view-gallary"
                      onClick={() => setIsOpen(true)}
                    >
                      View Gallary
                    </button>
                  </div>
                </div>

                {isOpen && (
                  <Lightbox
                    mainSrc={carGallary[photoIndex].image}
                    nextSrc={carGallary[(photoIndex + 1) % carGallary.length]}
                    prevSrc={
                      carGallary[
                        (photoIndex + carGallary.length - 1) % carGallary.length
                      ]
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex(
                        (photoIndex + carGallary.length - 1) % carGallary.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex((photoIndex + 1) % carGallary.length)
                    }
                  />
                )}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 right-box">
                <div className="car-info-div">
                  <h5 className="car-name">{carDetail?.car_name || ""}</h5>
                  <div className="car-date">
                    Year: {carDetail?.car_year || ""}
                  </div>
                  <ul>
                    <li>
                      <span className="car-sale-span">
                        {carDetail?.car_miles || ""}
                      </span>{" "}
                      mi
                    </li>
                    <li>{carDetail?.gear_type || ""}</li>
                    <li>
                      <span className="car-sale-span">
                        {carDetail?.engine || ""}
                      </span>{" "}
                      hp
                    </li>
                  </ul>

                  <div className="car-price">
                    Price : ${carDetail?.price || ""}
                  </div>

                  <div className="for-sale">For Sale</div>

                  <div className="vehicle-summary">Vehicle Summary</div>
                  <ul>
                    <li class="vehicle-summary-list-items">
                      <span>Fuel Type</span>
                      {carDetail?.fuel_type || ""}
                    </li>
                    <li class="vehicle-summary-list-items">
                      <span>Body Type</span>
                      {carDetail?.body_type || ""}
                    </li>
                    <li class="vehicle-summary-list-items">
                      <span>Doors</span>
                      {carDetail?.doors || ""}
                    </li>
                    <li class="vehicle-summary-list-items">
                      <span>Seats</span>
                      {carDetail?.seats || ""}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="car-features-div">
        <div className="container">
          <div className="main-content">
            <h4 className="secondary-title mb-4">Technical Information</h4>
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="detail-list">Dimensions</div>
                  <ul className="detail-list-name">
                    <li class="detail-list-items">
                      <span>Vehicle height</span>
                      {carDetail?.vehicale_height || ""} mm
                    </li>
                    <li class="detail-list-items">
                      <span>Vehicle length</span>
                      {carDetail?.vehicle_length || ""} mm
                    </li>
                    <li class="detail-list-items">
                      <span>Vehicle width</span>
                      {carDetail?.vehicle_width || ""} mm
                    </li>
                    <li class="detail-list-items">
                      <span>Boot space with seats up </span>
                      {carDetail?.boot_space || ""} litres
                    </li>
                    <li class="detail-list-items">
                      <span>Minimum kerb weight </span>
                      {carDetail?.kerb_weight || ""} kg
                    </li>
                  </ul>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="detail-list">Drivetrain & Performance</div>
                  <ul className="detail-list-name">
                    <li class="detail-list-items">
                      <span>Transmission</span>
                      {carDetail?.transmission || ""}
                    </li>
                    <li class="detail-list-items">
                      <span>Wheelbase</span>
                      {carDetail?.wheel_base || ""} mm
                    </li>
                    <li class="detail-list-items">
                      <span>Top speed</span>
                      {carDetail?.top_speed || ""} mph
                    </li>
                    <li class="detail-list-items">
                      <span>Engine power</span>
                      {carDetail?.engine_power || ""} bhp
                    </li>
                    <li class="detail-list-items">
                      <span>Engine torque</span>
                      {carDetail?.engine_torque || ""} lbs/ft
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <h4 className="secondary-title mb-4">Standard Equipment</h4>
            <div className="content">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn accordion-btn btn-link que btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Exterior
                      </button>
                    </h2>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body para-txt">
                      <ul>
                        <li>18in Alloy Wheels</li>
                        <li>Belt Line Moulding - Chrome</li>
                        <li>Bumpers - Colour Keyed</li>
                        <li>Door Handles - Body Colour</li>
                        <li>
                          Door Mirrors - Electrically Adjustable-Heated-Folding
                        </li>
                        <li>
                          Electric Sunroof - Glass Tilt and Slide and
                          Anti-trapping
                        </li>
                        <li>Privacy Glass - Rear Side and Rear Windows</li>
                        <li>Roof Rails - Black</li>
                        <li>Spoiler - Roof Body Colour</li>
                        <li>Wheel Arch Extensions - Black</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingSix">
                    <h2 className="mb-0">
                      <button
                        className="btn accordion-btn btn-link que btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseSix"
                        aria-expanded="true"
                        aria-controls="collapseSix"
                      >
                        Safety and Security
                      </button>
                    </h2>
                  </div>

                  <div
                    id="collapseSix"
                    className="collapse"
                    aria-labelledby="headingSix"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body para-txt">
                      <ul>
                        <li> ASC - Active Stability Control</li>
                        <li> Airbags - Front Passenger Deactivation Switch</li>
                        <li> Alarm</li>
                        <li> Brake Over Ride System</li>
                        <li> Central Door Locking - Keyless Entry</li>
                        <li> Curve Guidance Warning System</li>
                        <li> EBD - Electronic Brakeforce Distribution</li>
                        <li>
                          {" "}
                          RISE - Reinforced Impact Safety Evolution - Body
                          Safety System
                        </li>
                        <li> S-AWC - Super All Wheel Control</li>
                        <li> Traction Control - M-ASTC</li>
                        <li> Wipers - Intermittent with Washer - Rear</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn accordion-btn btn-link que btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Interior
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body para-txt">
                      <ul>
                        <li> Air Conditioning - Climate Control - Dual</li>
                        <li> Armrest - 2nd Row Centre with Cup Holders</li>
                        <li> Coat Hanger</li>
                        <li> Coat Hanger</li>
                        <li> Door Handles - Interior - Chrome</li>
                        <li> Door Trim Inserts - Leather</li>
                        <li> Electric Rear Tailgate</li>
                        <li> Engine Start-Stop Button</li>
                        <li> Footrest - Drivers</li>
                        <li> Fuel Lid Opener - Remote</li>
                        <li> Glovebox - Illuminated</li>
                        <li> Handbrake - Leather</li>
                        <li> Head Restraints - Height Adjustable Front x2</li>
                        <li> Head Restraints - Height Adjustable Rear</li>
                        <li> Headlining - Non Woven Fabric - Beige</li>
                        <li> Instrument Panel - Gloss Black Finish</li>
                        <li> Interior Light - Front</li>
                        <li> Interior Light - Rear</li>
                        <li> Interior Light with Map Reading Lights</li>
                        <li> Luggage Area Light</li>
                        <li> Pollen Filter</li>
                        <li> Seats - 60-40 Split Rear Folding Backrests</li>
                        <li> Seats - Drivers Electric 4-Way Adjustment</li>
                        <li>
                          {" "}
                          Seats - Front Slide and Recline Adjustment with Fully
                          Flat Function
                        </li>
                        <li> Seats - Heated Front</li>
                        <li> Sunvisors - Front Illuminated</li>
                        <li>
                          {" "}
                          Sunvisors x2 with Drivers Ticket Holder and Passengers
                          Vanity Mirror
                        </li>
                        <li> Window Demister with Timer - Rear</li>
                        <li>
                          {" "}
                          Wipers - Variable Intermittent with Washer - Front
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-5">
        <NavLink to="/buycar">
          <button type="button" class="btn btn-secondary">
            <i className="fa fa-arrow-left"></i>&nbsp; Back
          </button>
        </NavLink>
      </div>
    </>
  );
};

export default CarDetails;
