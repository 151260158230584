import OwlCarousel from "react-owl-carousel";
import { CLIENT_CARO } from "../../config/setting";
import AboutUs from "../../assets/images/Aboutusvector.jpg";
import Mission from "../../assets/images/Mission-Vector.jpg";
import Vision from "../../assets/images/vission-vector.svg";
import Testinomial1 from "../../assets/images/testinomial-1.jpg";

const About = () => {
  return (
    <>
      {/* about-us banner sec start */}
      <div className="about-us-main">
        <div className="main-content">
          <div className="content">
            <h1 className="main-title text-center">ABOUT US</h1>
            <h6 className="sub-title text-center">
              We are more than just a car dealership – we're your trusted automotive partner. With years of industry experience, we have earned a reputation for excellence in both sales and service.
            </h6>
          </div>
        </div>
      </div>
      {/* about-us banner sec end */}

      {/* who we are sec start */}
      <section className="aboutUs-section">
        <div className="who-we-are">
          <div className="main-content">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 left-div">
                  <div className="content">
                    <h1 className="secondary-title">About Us</h1>
                    <h6 className="para-txt">
                      Welcome to Auto ARC, your trusted destination for all things automotive. With years of experience in car sales and expert car repairs, we pride ourselves on delivering top-notch service and quality vehicles to our valued customers. Whether you're in the market for a reliable new ride or need professional maintenance and repair for your current car, our dedicated team is here to assist you every step of the way. At Auto ARC, your satisfaction is our priority.
                      <br></br>
                      <br></br>
                      At Auto ARC, we believe in building lasting relationships with our customers through honesty, transparency, and exceptional service. Our showroom features a wide selection of vehicles to suit every budget and lifestyle, and our knowledgeable sales team is dedicated to helping you find the perfect match. Our state-of-the-art service center is staffed by certified technicians who use the latest technology and genuine parts to keep your car running smoothly. From the moment you walk through our doors, you'll experience the Auto ARC difference – where quality meets reliability.
                    </h6>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 right-div">
                  <div className="img-box">
                    <img className="about-us-img" src={AboutUs} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* who we are sec end */}

      {/* our misson sec start */}
      <section className="aboutUs-section1">
        <div className="our-mission">
          <div className="main-content">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 left-sec">
                  <div className="img-box">
                    <img className="mission-img" src={Mission} alt="" />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 right-sec">
                  <div className="mission-content">
                    <h4 className="secondary-title">Our Mission</h4>
                    <h6 className="para-txt">
                      At Auto ARC, our mission is to revolutionize the car buying and servicing experience by prioritizing customer satisfaction and delivering unparalleled value. We are committed to offering a diverse selection of high-quality vehicles that cater to the unique needs and preferences of our customers. By maintaining a transparent and customer-centric approach, we strive to make every interaction straightforward and enjoyable, ensuring that you drive away with confidence and peace of mind.
                      <br></br>
                      <br></br>
                      Our dedication extends beyond sales to include comprehensive automotive care. Our mission is to provide exceptional repair and maintenance services that keep your vehicle in optimal condition. We invest in advanced diagnostic tools and ongoing training for our technicians to stay at the forefront of the industry. At Auto ARC, we believe in fostering trust through integrity and excellence, aiming to be your lifelong partner for all your automotive needs.
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutUs-section1">
        <div className="our-mission">
          <div className="main-content">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 left-sec">
                  <div className="mission-content">
                    <h4 className="secondary-title">Our Vision</h4>
                    <h6 className="para-txt">
                      Our vision at Auto ARC is to set the standard for excellence in the automotive industry by continuously evolving and innovating to meet the changing needs of our customers. We aspire to create a seamless and enjoyable car ownership experience, from the initial purchase to long-term maintenance and beyond. By embracing the latest advancements in automotive technology and sustainable practices, we aim to offer not only superior products and services but also contribute to a greener and more efficient future.
                      <br></br>
                      <br></br>
                      We envision a community where Auto ARC is synonymous with trust, quality, and customer satisfaction. Our goal is to expand our reach and impact, becoming the go-to destination for car buyers and owners who seek reliability and top-tier service. Through strategic partnerships, community engagement, and a relentless focus on improvement, we are committed to driving forward with integrity and a customer-first mindset, shaping a brighter future for the automotive landscape.
                    </h6>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 col-sm-12 right-sec">
                  <div className="img-box">
                    <img className="vision-img" src={Vision} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* our misson sec end */}

      {/* testimoial start */}
      <section className="testinomial-section">
        <div className="testimonial">
          <div className="container">
            <h1 className="secondary-title text-center mb-5">
              What Our Clients Are Saying!
            </h1>
            <OwlCarousel className="owl-theme client-main-caro" {...CLIENT_CARO}>
              <div className="main-box">
                <div className="content">
                  <div className="profile-img">
                    <div className="img-box">
                      <img className="testinomial-img" src={Testinomial1} alt=" " />
                    </div>
                    <div className="name">
                      <h3>David Smith</h3>
                      <h6>Customer of Auto ARC</h6>
                    </div>
                  </div>
                  <span>
                    "Auto ARC provided an outstanding car buying experience with exceptional customer service and top-quality vehicles."
                  </span>
                </div>
              </div>

              <div className="main-box">
                <div className="content">
                  <div className="profile-img">
                    <div className="img-box">
                      <img className="testinomial-img" src={Testinomial1} alt=" " />
                    </div>
                    <div className="name">
                      <h3>David Smith</h3>
                      <h6>Customer of Auto ARC</h6>
                    </div>
                  </div>
                  <span>
                    "Auto ARC provided an outstanding car buying experience with exceptional customer service and top-quality vehicles."
                  </span>
                </div>
              </div>

              <div className="main-box">
                <div className="content">
                  <div className="profile-img">
                    <div className="img-box">
                      <img className="testinomial-img" src={Testinomial1} alt=" " />
                    </div>
                    <div className="name">
                      <h3>David Smith</h3>
                      <h6>Customer of Auto ARC</h6>
                    </div>
                  </div>
                  <span>
                    "Auto ARC provided an outstanding car buying experience with exceptional customer service and top-quality vehicles."
                  </span>
                </div>
              </div>

              <div className="main-box">
                <div className="content">
                  <div className="profile-img">
                    <div className="img-box">
                      <img className="testinomial-img" src={Testinomial1} alt=" " />
                    </div>
                    <div className="name">
                      <h3>David Smith</h3>
                      <h6>Customer of Auto ARC</h6>
                    </div>
                  </div>
                  <span>
                    "Auto ARC provided an outstanding car buying experience with exceptional customer service and top-quality vehicles."
                  </span>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* testimonial end */}
    </>
  );
};

export default About;
