import { NavLink } from "react-router-dom";
import fullService1 from "../assets/images/full-service.png"
import fullService2 from "../assets/images/oil-cahnge.jpg"
import fullService3 from "../assets/images/auto-electric.jpg"
import fullService4 from "../assets/images/air-conditioning.jpg"
import fullService5 from "../assets/images/brake.jpg"
import fullService6 from "../assets/images/transmission.jpg"
import fullService7 from "../assets/images/wheel.jpg"
import fullService8 from "../assets/images/engine-diagnosis.jpg"

const carRepair = () => {
    return (
        <>
            <div className="repair-car-main">
                <div className="main-content">
                    <div className="content">
                        <h1 className="main-title pt-4 text-center ">REPAIR YOUR CAR</h1>
                        <h6 className="sub-title text-center">
                            We pride ourselves on being your one-stop destination for comprehensive car repair and service needs. Our expert technicians utilize advanced diagnostics and genuine parts to ensure your vehicle receives top-notch care.
                        </h6>
                        <br></br>
                        <NavLink to="/appointment">
                            <div className="text-center">
                                <button type="button" class="btn btn-secondary">Book Your Appointment Now</button>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
            <section>
                <div className="repair-car">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="repair-title text-center">
                                    <h2>Our Main Service Categories for Your Car</h2>
                                    <p>"From Tune-Up to Top-Notch: Your Car's Ultimate Destination for Care at Auto ARC."</p>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6 pb-5">
                                <div className="repair-card">
                                    <div className="contaniner">
                                        <img className="repair-img" src={fullService1} alt="" />
                                    </div>
                                    <div className="repair-body">
                                        <h5 className="repair-car-title">Full Car Service</h5>
                                        <p className="repair-text">Comprehensive full car service ensures your vehicle's peak performance and safety on the road.</p>
                                        {/* <NavLink to="#" className="repair-btn">Read More &nbsp;<i className="fa fa-arrow-right"></i></NavLink> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 pb-5">
                                <div className="repair-card">
                                    <div className="container">
                                        <img className="repair-img" src={fullService2} alt="" />
                                    </div>
                                    <div className="repair-body">
                                        <h5 className="repair-car-title">Oil Changes</h5>
                                        <p className="repair-text">Regular oil changes are key to maintaining engine health and performance, ensuring a smooth ride every time.</p>
                                        {/* <NavLink to="#" className="repair-btn">Read More &nbsp;<i className="fa fa-arrow-right"></i></NavLink> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 pb-5">
                                <div className="repair-card">
                                    <div className="container">
                                        <img className="repair-img" src={fullService3} alt="" />
                                    </div>
                                    <div className="repair-body">
                                        <h5 className="repair-car-title">Auto Electric</h5>
                                        <p className="repair-text">Expert auto electric services ensure your vehicle's electrical systems operate smoothly, keeping you safe and connected on the road.</p>
                                        {/* <NavLink to="#" className="repair-btn">Read More &nbsp;<i className="fa fa-arrow-right"></i></NavLink> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 pb-5">
                                <div className="repair-card">
                                    <div className="container">
                                        <img className="repair-img" src={fullService4} alt="" />
                                    </div>
                                    <div className="repair-body">
                                        <h5 className="repair-car-title">Air Conditioning</h5>
                                        <p className="repair-text">Stay cool and comfortable on the road with our expert air conditioning services, ensuring optimal performance in any weather.</p>
                                        {/* <NavLink to="#" className="repair-btn">Read More &nbsp;<i className="fa fa-arrow-right"></i></NavLink> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6 pb-5">
                                <div className="repair-card">
                                    <img className="repair-img" src={fullService5} alt="" />
                                    <div className="repair-body">
                                        <h5 className="repair-car-title">Brake Service</h5>
                                        <p className="repair-text">Ensure safe travels with our precision brake service, keeping your stopping power at its best.</p>
                                        {/* <NavLink to="#" className="repair-btn">Read More &nbsp;<i className="fa fa-arrow-right"></i></NavLink> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 pb-5">
                                <div className="repair-card">
                                    <img className="repair-img" src={fullService6} alt="" />
                                    <div className="repair-body">
                                        <h5 className="repair-car-title">Transmission</h5>
                                        <p className="repair-text">Keep your vehicle shifting smoothly with our expert transmission services, ensuring reliable performance mile after mile.</p>
                                        {/* <NavLink to="#" className="repair-btn">Read More &nbsp;<i className="fa fa-arrow-right"></i></NavLink> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 pb-5">
                                <div className="repair-card">
                                    <img className="repair-img" src={fullService7} alt="" />
                                    <div className="repair-body">
                                        <h5 className="repair-car-title">Wheel Service</h5>
                                        <p className="repair-text">Optimize your ride with our comprehensive wheel services, ensuring smooth handling and extended tire life.</p>
                                        {/* <NavLink to="#" className="repair-btn">Read More &nbsp;<i className="fa fa-arrow-right"></i></NavLink> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 pb-5">
                                <div className="repair-card">
                                    <img className="repair-img" src={fullService8} alt="" />
                                    <div className="repair-body">
                                        <h5 className="repair-car-title">Engine Diagnosis</h5>
                                        <p className="repair-text">Unlock the full potential of your vehicle with our advanced engine diagnostic services, pinpointing issues for precise repairs.</p>
                                        {/* <NavLink to="#" className="repair-btn">Read More &nbsp;<i className="fa fa-arrow-right"></i></NavLink> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default carRepair;