import { NavLink } from "react-router-dom";
import Car1 from "../../assets/images/car1.jpeg";
import Car2 from "../../assets/images/car2.jpeg";
import Car3 from "../../assets/images/car3.jpeg";
import Car4 from "../../assets/images/car4.jpeg";
import axios from "axios";
import { useEffect, useState } from "react";
// import Car3 from "../../assets/images/cars/car-2.jpg";
// import Car1 from "../../assets/images/cars/car-4.jpg";
// import Car2 from "../../assets/images/cars/car-6.jpg";
// import Car5 from "../../assets/images/cars/car-8.jpg";
// import Car4 from "../../assets/images/cars/car-2.jpg";
// import Car6 from "../../assets/images/cars/car-8.jpg";

const BuyCar = () => {
  const [carList, setCarList] = useState([]);

  const getCarList = async () => {
    try {
      const response = await axios.get("/user/cars?pagination=false");
      setCarList(response.data.payload);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCarList();
  }, []);

  return (
    <>
      <div className="buy-car-main">
        <div className="main-content">
          <div className="content">
            <h1 className="main-title pt-4 text-center ">Buy Your Own Car</h1>
            <h6 className="sub-title text-center ">
              <h6 className="buy-car-sub-info">
                We offer an extensive selection of quality vehicles to suit
                every taste and budget. Our dedicated sales team is committed to
                providing personalized assistance to help you find the perfect
                car that meets your needs and exceeds your expectations.
              </h6>
            </h6>
            <br></br>
            <NavLink to="/appointment">
              <div className="text-center">
                <button type="button" class="btn btn-secondary">
                  Book Your Appointment Now
                </button>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <section className="carSell">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="carSell-title">
                <span className="section-span">Cars for Sale</span>
                <h2>Select Your Car Here</h2>
                <p>
                  "Drive into Excellence: Find Your Perfect Car at Auto ARC."
                </p>
              </div>
            </div>
          </div>

          <div className="row carSell-item-pic-slider mt-4">
            {carList && carList.length > 0 ? (
              carList.map((c, index) => (
                <div className="col-xl-3 col-lg-3 col-md-3 col-12 col-sm-12" key={index}>
                  <div className="carSell-item">
                    <div className="img-box">
                      <img src={c?.pictures?.[0] || Car1} alt="" className="img-fluid" />
                    </div>
                    <div className="carSell-item-text">
                      <div className="carSell-item-text-inner">
                        <h5>
                          <NavLink to={`/car-details/${c._id}`}>
                            {c.car_name}
                          </NavLink>
                        </h5>
                        <div className="car-date">Year: {c.car_year}</div>
                        <ul>
                          <li>
                            <span className="car-sale-span">{c.car_miles}</span> mi
                          </li>
                          <li>{c.gear_type}</li>
                          <li>
                            <span className="car-sale-span">{c.engine}</span> hp
                          </li>
                        </ul>
                      </div>
                      <div className="carSell-item-price">
                        <span className="car-option sell">For Selling</span>
                        <h6>${c.price}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h1 className="text-center">No Car Found</h1>
            )}
          </div>

          {/* <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-4">
              <div className="carSell-item">
                <div className="buy-car-card-image">
                  <img className="buy-car-image" src={Car1} alt="" />
                </div>
                <div className="carSell-item-text">
                  <div className="carSell-item-text-inner">
                    <h5>
                      <NavLink to="/car-details">Toyota camry asv50l-jeteku</NavLink>
                    </h5>
                    <div className="car-date">Year: 2020</div>
                    <ul className="car-sell-text-list">
                      <li>
                        <span className="car-sale-span">35,000</span> mi
                      </li>
                      <li>Auto</li>
                      <li>
                        <span className="car-sale-span">700</span> hp
                      </li>
                    </ul>
                  </div>
                  <div className="carSell-item-price">
                    <span className="car-option sell">For Selling</span>
                    <h6>$73,900</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-4">
              <div className="carSell-item">
                <img className="buy-car-image" src={Car2} alt="" />
                <div className="carSell-item-text">
                  <div className="carSell-item-text-inner">
                    <h5>
                      <NavLink to="#">Toyota camry asv50l-jeteku</NavLink>
                    </h5>
                    <div className="car-date">Year: 2020</div>
                    <ul className="car-sell-text-list">
                      <li>
                        <span className="car-sale-span">35,000</span> mi
                      </li>
                      <li>Auto</li>
                      <li>
                        <span className="car-sale-span">700</span> hp
                      </li>
                    </ul>
                  </div>
                  <div className="carSell-item-price">
                    <span className="car-option sell">For Selling</span>
                    <h6>$73,900</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-4">
              <div className="carSell-item">
                <img className="buy-car-image" src={Car3} alt="" />
                <div className="carSell-item-text">
                  <div className="carSell-item-text-inner">
                    <h5>
                      <NavLink to="#">Toyota camry asv50l-jeteku</NavLink>
                    </h5>
                    <div className="car-date">Year: 2020</div>
                    <ul className="car-sell-text-list">
                      <li>
                        <span className="car-sale-span">35,000</span> mi
                      </li>
                      <li>Auto</li>
                      <li>
                        <span className="car-sale-span">700</span> hp
                      </li>
                    </ul>
                  </div>
                  <div className="carSell-item-price">
                    <span className="car-option sell">For Selling</span>
                    <h6>$73,900</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-4">
              <div className="carSell-item">
                <img className="buy-car-image" src={Car4} alt="" />
                <div className="carSell-item-text">
                  <div className="carSell-item-text-inner">
                    <h5>
                      <NavLink to="#">Toyota camry asv50l-jeteku</NavLink>
                    </h5>
                    <div className="car-date">Year: 2020</div>
                    <ul className="car-sell-text-list">
                      <li>
                        <span className="car-sale-span">35,000</span> mi
                      </li>
                      <li>Auto</li>
                      <li>
                        <span className="car-sale-span">700</span> hp
                      </li>
                    </ul>
                  </div>
                  <div className="carSell-item-price">
                    <span className="car-option sell">For Selling</span>
                    <h6>$73,900</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-4">
              <div className="carSell-item">
                <img className="buy-car-image" src={Car5} alt="" />
                <div className="carSell-item-text">
                  <div className="carSell-item-text-inner">
                    <h5>
                      <NavLink to="#">Toyota camry asv50l-jeteku</NavLink>
                    </h5>
                    <div className="car-date">Year: 2020</div>
                    <ul className="car-sell-text-list">
                      <li>
                        <span className="car-sale-span">35,000</span> mi
                      </li>
                      <li>Auto</li>
                      <li>
                        <span className="car-sale-span">700</span> hp
                      </li>
                    </ul>
                  </div>
                  <div className="carSell-item-price">
                    <span className="car-option sell">For Selling</span>
                    <h6>$73,900</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-4">
              <div className="carSell-item">
                <img className="buy-car-image" src={Car6} alt="" />
                <div className="carSell-item-text">
                  <div className="carSell-item-text-inner">
                    <h5>
                      <NavLink to="#">Toyota camry asv50l-jeteku</NavLink>
                    </h5>
                    <div className="car-date">Year: 2020</div>
                    <ul className="car-sell-text-list">
                      <li>
                        <span className="car-sale-span">35,000</span> mi
                      </li>
                      <li>Auto</li>
                      <li>
                        <span className="car-sale-span">700</span> hp
                      </li>
                    </ul>
                  </div>
                  <div className="carSell-item-price">
                    <span className="car-option sell">For Selling</span>
                    <h6>$73,900</h6>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default BuyCar;
